import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { routes } from 'inkp-routes/public';
import globalConfig from 'inkp-config';
import Logo from 'inkp-components/dist/Components/Logo';
import LayoutFooter from 'inkp-components/dist/Components/LayoutFooter';

import config from '../config';

interface Props extends RouteComponentProps {
  viewId: string;
  title: string;
}

export default class CheckoutLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { title } = this.props;
    const isCartView: boolean = this.props.match.url === routes.app.checkout.cart;
    const isOrderConfirmationView: boolean = this.props.match.path === routes.app.order.confirmation;
    return (
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-no-grow flex-no-shrink flex-basis-auto items-center px-1 py-p5 bc-navy-200 bwb-1 md:px-1p5 md:py-p5">
          <div className="w-1/6 ta-left">
            <div className="d-b md:d-n">
              <Logo width="36px" height="32px" type="logo-only" id="mobile" className="d-b" />
            </div>
            <div className="d-n md:d-b">
              <Logo width="125px" height="32px" id="desktop" className="d-b" />
            </div>
          </div>
          <div className="w-4/6 ta-center">
            <h1 className="fs-md fw-extra-bold capitalize md:fs-xl">{title}</h1>
          </div>
          <div className="w-1/6 ta-right">
            <div className="flex justify-end">
              <a className="flex color-navy-800" href={`tel:${globalConfig.contact.phone}`}>
                <span className="d-b py-2px md:px-p5">
                  <i className="mdi mdi-headset mdi-24px"></i>
                </span>
                <span className="d-n md:d-b">
                  <span className="color-primary">
                    <style jsx>
                      {`
                        span {
                          font-size: 10px;
                          line-height: 15px;
                          height: 15px;
                        }
                      `}
                    </style>
                    Talk to a Specialist
                  </span>
                  <span className="d-b fs-md-n">
                    {globalConfig.contact.phone.replace(/^\d{3}-/, (match: string) => `(${match.slice(0, 3)}) `)}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="flex-no-shrink flex-grow flex-basis-auto">{this.props.children}</div>
        {(isCartView || isOrderConfirmationView) && (
          <div className="flex-no-grow flex-no-shrink flex-basis-auto">
            <LayoutFooter
              phone={globalConfig.contact.phone}
              links={{
                aboutUs: routes.app.about,
                blog: config.blog,
                sendEmail: '#',
                retrieveDesign: routes.app.account.retrieveDesign,
                faq: `${routes.app.help}?tab=Help%20%26%20FAQ`,
                privacyPolicy: routes.app.privacyPolicy,
                termsService: routes.app.termsOfService,
                returnsExchanges: `${routes.app.help}?tab=Returns%20%26%20Exchanges`,
                fb: globalConfig.contact.facebookPageUrl,
                instagram: globalConfig.contact.instagramPageUrl,
                pinterest: globalConfig.contact.pinterestPageUrl,
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
